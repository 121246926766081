import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { loadStripe } from "@stripe/stripe-js";
import { Elements } from "@stripe/react-stripe-js";
import axios from "axios";
import { OrderDetails } from "./models";
import PaymentForm from "./PaymentForm";

const stripePromise = loadStripe(process.env.REACT_APP_STRIPE_PUBLISHABLE_KEY!);
const API_URL = process.env.REACT_APP_API_URL;

const PaymentInstalment: React.FC = () => {
  const location = useLocation();
  const [orderDetails, setOrderDetails] = useState<OrderDetails | null>(null);
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState<string | null>(null);

  useEffect(() => {
    const fetchOrderDetails = async () => {
      const params = new URLSearchParams(location.search);
      const orderId = params.get("orderId");

      if (!orderId) {
        setError("Order ID is missing from the URL.");
        setIsLoading(false);
        return;
      }

      try {
        const response = await axios.get<OrderDetails>(
          `${API_URL}/payment_plan/order?orderId=${orderId}`
        );
        setOrderDetails(response.data);
      } catch (err) {
        setError("Failed to fetch order details");
      } finally {
        setIsLoading(false);
      }
    };

    fetchOrderDetails();
  }, [location]);

  if (isLoading)
    return <div className="text-white text-center">Loading...</div>;
  if (error || !orderDetails)
    return (
      <div className="text-red-500">
        {error || "Failed to load order details"}
      </div>
    );

  return (
    <div className="bg-gray-900 min-h-screen p-4">
      <div className="max-w-2xl mx-auto">
        <h1 className="text-3xl font-bold mb-6 text-white">
          Payment Plan Details
        </h1>
        <Elements stripe={stripePromise}>
          <PaymentForm orderDetails={orderDetails} />
        </Elements>
      </div>
    </div>
  );
};

export default PaymentInstalment;
