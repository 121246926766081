import { FC, useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import BgGlassmorphism from "components/BgGlassmorphism/BgGlassmorphism";
import { Disclosure } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/20/solid";

export interface PageTermsAndConditionsProps {
  className?: string;
}

const PageTermsAndConditions: FC<PageTermsAndConditionsProps> = ({
  className = "",
}) => {
  const location = useLocation();
  const [openSection, setOpenSection] = useState("");
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (location) {
      const params = new URLSearchParams(location.search);
      console.log("params ::: ", params);
      const section = params.get("section");
      console.log("section ::: ", section);
      if (section) {
        console.log("section ::: ", section);
        setOpenSection(section);
      }
      setLoading(false);
    } else {
      setLoading(false);
    }
  }, [location]);

  useEffect(() => {
    if (openSection) {
      document
        .getElementById(openSection)
        ?.scrollIntoView({ behavior: "smooth" });
    }
  }, [openSection]);

  return (
    <div
      className={`nc-PageTermsAndConditions overflow-hidden relative ${className}`}
      data-nc-id="PageTermsAndConditions"
    >
      <Helmet>
        <title>Terms and Conditions | My Flight Zone</title>
        <meta
          name="description"
          content="Dive into My Flight Zone's Terms and Conditions for a comprehensive grasp of the rules and regulations that oversee our services and bookings."
        />
      </Helmet>

      {/* ======== BG GLASS ======== */}
      <BgGlassmorphism />

      <div className="container py-16 lg:py-28 space-y-16 lg:space-y-28">
        {!loading && (
          <div
            className={`nc-SectionHero relative ${className}`}
            data-nc-id="SectionHero"
          >
            <div className="flex flex-col lg:flex-row space-y-14 lg:space-y-0 lg:space-x-10 items-center relative text-center lg:text-left">
              <div className="flex-grow">
                <h1 className="text-3xl !leading-tight font-semibold text-neutral-900 md:text-4xl xl:text-5xl dark:text-neutral-100">
                  Terms and Conditions
                </h1>
                <br />
                <span className="block text-base xl:text-lg text-neutral-6000 dark:text-neutral-400">
                  <div>
                    <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800 mb-10">
                      <p>
                        These terms and conditions ('terms') govern the
                        relationship between Passenger/Customer and Travel Zone
                        Holidays Pty Ltd trading as My Flight Zone based in
                        Cranbourne North, Victoria, Australia, under ABN 98159
                        619 691 and its related entities, in relation to flight
                        bookings and holiday Packages.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        Please read these Terms and Conditions carefully. You
                        must only make a booking if you are 18 or older and
                        understand and agree with the following Terms and
                        Conditions.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        These Terms and Conditions apply to all Bookings you
                        make with My Flight Zone and all other products and
                        services you receive from My Flight Zone. These Terms
                        and Conditions govern our relationship with you. Using
                        our booking and other services, you acknowledge that you
                        have read and understood and agree to be bound by these
                        terms.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        Please read the following "Booking Terms and Conditions"
                        carefully, as they contain important information about
                        your legal rights, remedies, and obligations. You must
                        only make a booking if you understand and agree with the
                        following Booking Terms and Conditions.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        References to "us," "we," and/or "our" in these Booking
                        Terms and Conditions shall mean the Travel Consultant as
                        set out in your quote, tax invoice and/or itinerary (as
                        applicable). References to " My Flight Zone " in these
                        Booking Terms and Conditions shall mean and its wholly
                        owned subsidiaries. References to "you" and "your" in
                        these Booking Terms and Conditions shall mean the
                        customer.
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        You agree to comply with and be bound by these Booking
                        Terms and Conditions by making any booking. These
                        Booking Terms and Conditions apply to any bookings you
                        make with us (online, over the phone, or by visiting our
                        office).
                      </p>
                      <p>&nbsp;</p>
                      <p>
                        We will rely on the authority of the person making the
                        booking to act on behalf of any other traveller on the
                        booking (regardless of whether other travellers have
                        made separate payments), and that person will bind all
                        such travellers to these Booking Terms and Conditions.
                      </p>
                    </div>
                    <div>
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>
                                Requirements to Travel: Passport and Visas:
                              </span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Requirements to Travel: Passport and Visas:
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    All travellers must have a valid passport
                                    for international travel, and many countries
                                    require at least 6 months of validity from
                                    the return date. For international travel
                                    bookings, you must let us know if you have
                                    less than 6 months of validity on your
                                    passport when assisting with an
                                    international travel booking.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Please ensure you have a valid passport in
                                    advance of travel, as either the airline
                                    staff or immigration authorities will not
                                    allow you to pass through customs and board
                                    your flight without this documentation.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    You must ensure that you have valid
                                    passports, visas, and re-entry permits that
                                    meet the requirements of immigration and
                                    other government authorities. Any fines,
                                    penalties, payments, or expenditures
                                    incurred as a result of such documents not
                                    meeting the needs of those authorities will
                                    be your sole responsibility.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Visas are obtained from the issuing
                                    authority, usually an embassy. Visa
                                    requirements vary by the country you are
                                    travelling to and your current citizenship,
                                    so please ensure you check well in advance
                                    of your travel dates to allow sufficient
                                    processing time as required by the embassy.
                                    If you have booked a one-way ticket, please
                                    check with the Consulate of the country you
                                    are travelling to ensure that you comply
                                    with their laws and regulations.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    We recommend before travelling that you
                                    check the Government website relating to the
                                    countries you are travelling to for any
                                    applicable travel advisories relevant to
                                    those countries.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>&nbsp;</p>
                                  <p>
                                    If You Are Travelling to Or Transiting
                                    Through Sri Lanka,
                                  </p>
                                  <p>
                                    Please see
                                    https://eta.gov.lk/slvisa/visainfo/contact.jsp&nbsp;for
                                    important information.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    If You Are Travelling to Or Transiting
                                    Through the United States,
                                  </p>
                                  <p>
                                    Please
                                    see&nbsp;https://esta.cbp.dhs.gov&nbsp;for
                                    important information regarding compulsory
                                    pre-registration for their visa waiver
                                    program ("ESTA"). Australian passport
                                    holders can only enter the United States
                                    with a valid ESTA (or visa). Please note you
                                    may not meet the eligibility requirements of
                                    ESTA and may be required to obtain a visa.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    If You Are Travelling to Or Transiting
                                    Through Canada,
                                  </p>
                                  <p>
                                    Please
                                    see&nbsp;http://www.cic.gc.ca/english/visit/eta.asp
                                    for important information regarding
                                    compulsory electronic travel authorization
                                    for visa-exempt foreign nationals ("ETA").
                                    Australian passport holders can only enter
                                    Canada with a valid ETA (or visa or permit)
                                    from 15 March 2016. Please note that you may
                                    not meet ETA's eligibility requirements and
                                    may be required to obtain a visa or permit.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Please apply for an ESTA or ETA before
                                    booking airfare if you have concerns about
                                    whether you are eligible for an ESTA or ETA.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>All other Visa requirements,</p>
                                  <p>
                                    Please visit
                                    https://apply.joinsherpa.com/explore/AUS?affiliateId=sherpa
                                    for more information.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Services We Provide</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Services We Provide
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    We provide a travel booking service that
                                    enables you to:
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    a)&nbsp;Book or purchase travel-related
                                    goods and services promoted on our website
                                    or at the office.
                                  </p>
                                  <p>
                                    b)&nbsp;Conduct flight and other
                                    travel-related searches and reservations.
                                  </p>
                                  <p>
                                    c)&nbsp;Access our Customer Service
                                    assistance service.
                                  </p>
                                  <p>
                                    d)&nbsp;Use our payment processing and
                                    ticketing services.
                                  </p>
                                  <p>
                                    e)&nbsp;When booking or purchasing a Travel
                                    Product, we act on behalf of the supplier to
                                    accept your booking and payment. Each
                                    booking you make forms a contract between
                                    you and the applicable supplier and is
                                    subject to the Product Terms, including
                                    conditions of carriage and limitations of
                                    liability, imposed by the supplier. We
                                    recommend that you read the Product Terms
                                    before finalizing your booking. We can
                                    provide copies of the relevant terms and
                                    conditions on request.
                                  </p>
                                  <p>
                                    f)&nbsp;When you make a flight Booking
                                    through My Flight Zone, we provide you with
                                    access to our global Customer Service
                                    offering. The Customer Service provided
                                    includes access to staff online, by email,
                                    or by phone to confirm your booking, make
                                    any changes to your booking, or otherwise
                                    seek assistance. The Customer Service
                                    provided by My Flight Zone is global and can
                                    be accessed anywhere. There are charges
                                    applicable on these services as per the
                                    Terms and Conditions.
                                  </p>
                                  <p>&nbsp;</p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Travel Insurance</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Travel Insurance
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  We strongly recommend you take out appropriate
                                  travel insurance to cover your travel
                                  arrangements. Your insurance protection should
                                  include cover for cancellation, medical and
                                  repatriation expenses, personal injury and
                                  accident, death and loss of personal baggage
                                  and money, and personal liability insurance.
                                  Evidence of such insurance should be produced
                                  to us on request. The Insurance coverage
                                  offered by credit card companies or reciprocal
                                  medical cover agreements is often not
                                  comprehensive. Travel insurance is strongly
                                  recommended by the Department of Foreign
                                  Affairs and Trade for all overseas travel.
                                  Travel insurances are fully non-refundable
                                  once issued.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>
                                Travel Advice Including COVID-19 (Coronavirus)
                                Guidance:
                              </span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Travel Advice Including COVID-19 (Coronavirus)
                                  Guidance:
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    We recommend that you contact the Department
                                    of Foreign Affairs and Trade ("DFAT") or
                                    visit their website at
                                    www.smartraveller.gov.au for general travel
                                    advice, as well as specific advice
                                    (including safety alert levels) relating to
                                    the destination you wish to visit. You can
                                    also register your travel plans with DFAT so
                                    that you may be more easily contacted in an
                                    emergency.
                                  </p>
                                  <p>
                                    We strongly recommend that you familiarise
                                    yourself with the current status and updates
                                    to Australia's immigration and border
                                    arrangements during the COVID-19 outbreak,
                                    available at covid19.homeaffairs.gov.au. In
                                    addition, you should familiarise yourself
                                    with airline requirements around passenger
                                    safety, including the requirement for face
                                    masks and the need to produce evidence
                                    meeting airline and border control
                                    requirements in relation to a negative
                                    COVID-19 test for transit and final
                                    destination passengers. This information is
                                    subject to change without notice, so we
                                    recommend you update yourself in relation to
                                    the relevant airline and government policies
                                    for your transit and destination at each of
                                    (i) the time of booking, (ii) as you
                                    approach your travel date, and (iii)
                                    immediately before travel. You acknowledge
                                    that you are choosing to travel at a time
                                    when you may be exposed to the Coronavirus.
                                    Your responsibility is to acquaint yourself
                                    with all relevant travel information,
                                    including applicable health risks. You
                                    acknowledge that your decision to travel is
                                    made based on your consideration of this
                                    information, and you accept and agree that
                                    you are aware of and assume responsibility
                                    for the risks associated with travelling at
                                    this time. To the fullest extent permitted
                                    by law, we accept no liability in relation
                                    to these additional risks.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Health</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Health
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  You must ensure that you are aware of any
                                  health requirements, and recommended
                                  precautions relevant to your travel booking
                                  and that you carry all necessary vaccination
                                  documentation. In some cases, you must present
                                  required vaccination documentation (e.g.,
                                  proof of Yellow Fever vaccination) to allow
                                  entry into a country. We recommend you consult
                                  your local doctor, travel medical service, or
                                  specialist vaccination clinic before
                                  commencing your travel. General health advice
                                  for the destination you wish to visit is also
                                  available from DFAT (see
                                  www.smartraveller.gov.au). Whether any medical
                                  requests can be accommodated, including
                                  (without limitation) access to power,
                                  refrigeration, and traveling with the use of
                                  mobility aids, is subject to the travel
                                  service provider in their sole and absolute
                                  discretion and will often depend on several
                                  factors, including (without limitation) any
                                  modes of transport and local standards at the
                                  destination. All medical requests are beyond
                                  our control. While we will include all medical
                                  requests as a file note to the travel service
                                  provider, we cannot guarantee that your
                                  request will be accommodated. You are
                                  responsible for following up with the travel
                                  service provider directly at the destination
                                  or before travel where possible.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Financial Arrangements</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Financial Arrangements
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  We receive remuneration through commissions,
                                  financial incentives, and other means
                                  (together, "financial arrangements") from
                                  booking travel and travel-related products and
                                  services on your behalf with third-party
                                  travel service providers. We are not required
                                  by law to disclose the nature or value of
                                  these financial arrangements.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Deposit and Final Payment</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Deposit and Final Payment
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    You will be required to pay a deposit or
                                    deposits when booking. We will advise you of
                                    how much that will be. Subject to your
                                    rights under the Australian Consumer Law,
                                    all deposits are non-refundable for changes
                                    of mind or cancellations by you, or if the
                                    travel service provider's terms and
                                    conditions provide that your deposit is
                                    non-refundable.
                                  </p>

                                  <p>
                                    If your deposit is refundable, this is
                                    subject to us having received the funds from
                                    the travel service provider and/or is
                                    authorized by the travel service provider to
                                    refund your deposit.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Our Change and Cancellation Fees</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Our Change and Cancellation Fees
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  Subject to your refund and remedy rights under
                                  the Australian Consumer Law, any change and
                                  cancellation fees that will apply to your
                                  booking are set out in your quote, tax invoice
                                  and/or itinerary (as applicable) and as set
                                  out in sections 9 to 13 below: Payment of
                                  change or cancellation fees via credit card
                                  may attract the relevant credit card
                                  surcharge. All bookings are made on your
                                  behalf subject to the terms and conditions
                                  imposed by the travel service provider. If,
                                  for example, a travel service provider's terms
                                  and conditions contain a "no refund policy,"
                                  we will only be able to provide you with the
                                  remedy provided by the travel service provider
                                  (if any), which may include a travel credit
                                  supplied by the travel service provider.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>
                                Travel Service Provider Change and Cancellation
                                Fees
                              </span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Travel Service Provider Change and
                                  Cancellation Fees
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  Changed or cancelled bookings for any reason
                                  (including due to reasons or matters outside
                                  your or our control) may also incur travel
                                  service provider fees, which can be up to 100%
                                  of the cost of the booking, regardless of
                                  whether travel has commenced. Travel service
                                  provider fees may also apply where a booking
                                  is changed for any reason and when tickets or
                                  documents are re-issued. Where we incur any
                                  liability for a travel service provider change
                                  or cancellation fee for any booking which is
                                  changed or cancelled for any reason, you agree
                                  to indemnify us for the amount of that fee.
                                  Where you seek a refund for a changed or
                                  cancelled booking for which payment has been
                                  made to the travel service provider, we will
                                  not provide a refund to you until we receive
                                  the funds from that travel service provider
                                  (which may take 12 weeks or longer, dependent
                                  upon the travel service provider processing
                                  time). If we are still holding the funds, we
                                  can only provide you with a refund once the
                                  travel service provider authorizes us to
                                  process your refund, subject to that travel
                                  service provider's change or cancellation
                                  policy.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Change or Cancellation by You</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Change or Cancellation by You
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    If you change any aspect of your booking, we
                                    will do our best to accommodate your
                                    request, but it may only sometimes be
                                    possible. All changes will be subject to
                                    applicable travel service provider fees and
                                    any change fees set out in your quote, tax
                                    invoice and/or itinerary (as appropriate),
                                    and you may be responsible for any increase
                                    in pricing that may occur as a result of
                                    your change request.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    You will forfeit your deposit if you cancel
                                    any aspect of your booking. You will be
                                    required to pay applicable travel service
                                    provider fees, and cancellation fees set out
                                    in your quote, tax invoice and/or itinerary
                                    (as appropriate). We will provide you with a
                                    refund for the remaining funds (if any).
                                    Alternatively, the travel service provider
                                    or we may offer you a travel credit for the
                                    total amount paid by you without applying
                                    any applicable travel service provider fees
                                    or our applicable cancellation fees. If your
                                    deposit or booking is refundable, this is
                                    subject to us having received the funds from
                                    the travel service provider and/or being
                                    authorized by the travel service provider to
                                    refund your deposit or booking funds.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    For instant purchases or non-refundable
                                    bookings, if you cancel any aspect of your
                                    booking, you will not be entitled to a
                                    refund, travel credit, or other remedies
                                    from the travel service provider and/or us.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    <strong>
                                      Changes to International / Domestic
                                      Bookings,
                                    </strong>
                                  </p>
                                  <p>
                                    If any ticket has been issued that requires
                                    a change, for example, to date, a re-issue
                                    fee will apply at a price of AUD 75.00 per
                                    change plus any re-issue fee, fare
                                    difference, and tax difference payable to
                                    the airline. In addition, airlines may
                                    charge additional fees and taxes or local
                                    office fees if travel has commenced and the
                                    ticket requires a re-issue.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    All Changes need to be done 08 hours prior
                                    to your flight departure, any changes after
                                    that the No-Show fee will apply as a Late
                                    Change Fee apart from the above charges.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    <strong>
                                      Cancellations Of Domestic Bookings,
                                    </strong>
                                  </p>
                                  <p>
                                    Domestic tickets and Tickets issued on
                                    Low-Cost Carriers are Non-Refundable and
                                    Non-Changeable.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    <strong>
                                      Cancellations Of International Bookings
                                      Towards Refund or Credit for Travel In
                                      Economy Class,
                                    </strong>
                                  </p>
                                  <p>
                                    All cancellations are governed by the terms
                                    and conditions of the airlines booked for
                                    services. After confirmation, any
                                    cancellation of any component of your travel
                                    flight will incur AUD 75.00 per person as a
                                    cancellation fee levied by My Flight Zone,
                                    plus any cancellation fee that may be
                                    imposed by the airline.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Infant tickets are always non-refundable in
                                    all circumstances.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Where we incur any liability for a
                                    cancellation fee for any booking you cancel,
                                    you agree to indemnify us for that fee or
                                    charge.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Cancellation or refund will only be
                                    processed if you have sent the request in
                                    writing.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Suppose the cancellation is due to the
                                    airline's schedule change/flight
                                    cancellation. In that case, a service fee of
                                    AUD 50.00 per booking is applicable plus any
                                    cancellation fee imposed by the airline.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    The service and cancellation fees reflect
                                    the reasonable, direct, and indirect costs,
                                    time, and effort incurred or involved in us
                                    providing booking and advisory services to
                                    you, as well as the processing and managing
                                    the changes to or cancellation of your
                                    booking or putting them towards credit for
                                    future use.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Dupe Bookings</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Dupe Bookings
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    If you have created another
                                    booking/reservation with another agent or
                                    directly from the airline, the bookings can
                                    be duped and cancelled without prior notice.
                                  </p>
                                  <br />
                                  <p>
                                    It may lead us NOT TO OFFER you the same
                                    fare even though you have completed the
                                    payment within the given time limit. We
                                    would have to rebook the entire itinerary
                                    with the presently available rates and
                                    re-quote it for your approval.
                                  </p>
                                  <br />
                                  <p>
                                    After you complete the payment if the
                                    booking got cancelled under DUPE booking by
                                    the airline, we have to re-quote the airfare
                                    according to the available booking classes.
                                    If you disagree with the new fare levels,
                                    the paid amount will be refunded after
                                    deducting $75.00 from the service fee.
                                  </p>
                                  <br />
                                  <p>
                                    To avoid such incidents, cancel the other
                                    bookings or advise us about the other
                                    bookings before completing the payment.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>
                                Change or Cancellation by A Travel Service
                                Provider
                              </span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Change or Cancellation by A Travel Service
                                  Provider
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    The following terms apply to a cancellation
                                    by a travel service provider, except in
                                    unavoidable or extraordinary circumstances
                                    (which are dealt with in sections 12 and 13
                                    below).
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    To the extent permitted by applicable law,
                                    if a travel service provider cancels your
                                    booking, the travel service provider will
                                    generally offer you in the first instance,
                                    alternative travel arrangements of
                                    comparable standard if available, or travel
                                    credit for the total amount paid by you.
                                    Alternatively, subject to the travel service
                                    provider's change or cancellation policy,
                                    the travel service provider may offer you a
                                    refund of all money paid by you in respect
                                    of the booking, from which the travel
                                    service provider and/or we will deduct any
                                    unrecoverable costs, and any applicable
                                    travel service provider fees and our
                                    applicable cancellation fees.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    "Unrecoverable costs" means all reasonable,
                                    direct, and indirect costs the travel
                                    service provider and/or we have incurred in
                                    relation to your booking and includes
                                    amounts paid by us to other relevant travel
                                    service providers who are responsible for
                                    components of your booking, and which may be
                                    non-refundable—for example, costs paid to
                                    overseas in-destination tour or transfer
                                    operators.
                                  </p>
                                  <p>
                                    For instant purchase or non-refundable
                                    bookings, if your booking is cancelled by a
                                    travel service provider, subject to the
                                    travel service provider's change or
                                    cancellation policy, you may not be entitled
                                    to a refund, travel credit, or other
                                    remedies from the travel service provider
                                    and/or us.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    <strong>
                                      International / Domestic Flights:&nbsp;
                                    </strong>
                                    In case of any Waivers for Full Refund due
                                    to any changes or cancellations by the
                                    airline, our service fee of AUD 50.00 per
                                    booking applies on all flights.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Unavoidable Circumstances</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Unavoidable Circumstances
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    In the event of unavoidable or extraordinary
                                    circumstances, a travel service provider may
                                    materially modify or cancel your booking as
                                    set out below.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    In these Booking Terms and Conditions
                                    "unavoidable or extraordinary circumstances"
                                    means any cause outside a travel service
                                    provider's reasonable control which could
                                    not have been prevented or avoided even if
                                    all appropriate measures had been taken by
                                    the travel service provider (including, but
                                    not limited to, war, threat of war, riot,
                                    civil disturbances, industrial dispute,
                                    terrorist activity and its consequences,
                                    plague, epidemic, pandemic, infectious
                                    disease outbreak or any other public health
                                    crisis (including quarantine or other
                                    employee restrictions), natural or other
                                    disaster (such as volcanic ash or hurricanes
                                    or similar events), nuclear incident, fire
                                    or bushfires, adverse weather conditions
                                    (actual or threatened, including snow and
                                    fog), closed or congested airports or ports
                                    or other modes of transport, unavoidable
                                    technical problems with transport,
                                    unforeseen alterations to transport
                                    schedules, transportation disruptions or
                                    cancellations, domestic and/or international
                                    travel restrictions, changes to travel
                                    advisories and restrictions, changes to
                                    health advisories and quarantines, changes
                                    to immigration, labour and free-movement
                                    laws resulting from BREXIT, rescheduling of
                                    aircraft or boats or other modes of
                                    transport, changes to applicable laws and/or
                                    other government mandates (including
                                    evacuation orders and border closures), and
                                    similar events).
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    If your booking is cancelled due to
                                    unavoidable or extraordinary circumstances,
                                    either the travel service provider will
                                    offer you a travel credit or we may, subject
                                    to any applicable hardship policy, offer you
                                    a travel credit or Refund amount paid by you
                                    after deduction of our Service fees of AUD
                                    50.00 per booking.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>No Show</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  No Show
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  Please occupy a reserved seat on any segment
                                  of the itinerary to avoid all subsequent
                                  segments of the itinerary being cancelled. In
                                  such a case, re-issue will incur a charge of
                                  AUD 750.00 plus any additional fare plus My
                                  Flight Zone Handling fee of AUD 75.00 per
                                  person.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>
                                Payments by Credit Card and Debit Card
                              </span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Payments by Credit Card and Debit Card
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    Any credit card surcharges as set out in
                                    your quote, tax invoice and/or itinerary (as
                                    applicable) will apply when paying by credit
                                    card or debit card.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    You authorize us to charge all fees incurred
                                    by you in relation to the services provided
                                    by us to the credit card or debit card
                                    designated by you. If payment is not
                                    received from the card issuer or its agents
                                    for any reason, you agree to pay us all
                                    amounts due immediately on demand.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Surcharges apply when paying by credit/debit
                                    card. If payment has been made to My Flight
                                    Zone by credit/debit card, you agree that
                                    you will not seek to claim back from My
                                    Flight Zone. A Surcharge of 2% fee will
                                    apply on Master and Visa Cards and 3.5% on
                                    American Express cards.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Validity</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Validity
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  Air Tickets are only valid for 350 days from
                                  the issued date. After that, if the ticket has
                                  not been used total ticket value will be
                                  forfeited by the Travel Service Provider. Air
                                  Tickets are non-transferable after issuance.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Our Agency</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Our Agency
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    We act as an agent for and sell various
                                    travel-related products as an agent on
                                    behalf of Airlines / Hotels and Tour
                                    Operators. Therefore, any booking, advisory,
                                    and other services (such as processing a
                                    change or cancellation with the relevant
                                    travel services provider) that we provide to
                                    you are collateral to that agency
                                    relationship.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Our obligation to you is to (and you
                                    expressly authorize us to) provide booking
                                    and advisory services, including making
                                    travel bookings on your behalf and arranging
                                    relevant contracts between you and travel
                                    service providers. We exercise care in
                                    selecting reputable travel service
                                    providers. Still, we are not a provider of
                                    travel services and have no control over, or
                                    liability for, the services provided by
                                    third parties. All bookings are made on your
                                    behalf subject to the terms and conditions,
                                    including conditions of carriage,
                                    cancellations policy, and limitations of
                                    liability, imposed by these travel service
                                    providers. We can provide copies of the
                                    relevant travel service provider terms and
                                    conditions on request. Your legal rights in
                                    connection with the provision of travel
                                    services are against the specific travel
                                    service provider and, except to the extent a
                                    problem is caused by fault on our part, are
                                    not against us. Specifically, suppose for
                                    any reason (excluding fault on our part),
                                    any travel service provider is unable to
                                    provide the services for which you have
                                    contracted. In that case, your rights are
                                    against that provider and not against us.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Our Liability</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Our Liability
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    To the extent permitted by law, we do not
                                    accept any liability in contract, tort, or
                                    otherwise for any injury, damage, loss
                                    (including consequential loss), delay,
                                    additional expense, or inconvenience caused
                                    directly or indirectly by the acts,
                                    omissions or default, whether negligent or
                                    otherwise, of third party providers over
                                    whom we have no direct control, force
                                    majeure, supplier insolvency or any other
                                    event which is beyond our control or which
                                    is not preventable by reasonable diligence
                                    on our part.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Our liability will also be limited to the
                                    extent that any relevant international
                                    conventions, for example, the Montreal
                                    Convention in respect of travel by air,
                                    limit the amount of compensation that can be
                                    claimed for death, injury, or delay to
                                    passengers and loss, damage, and delay to
                                    luggage. Under circumstances where our
                                    liability cannot be excluded and where
                                    liability may be lawfully limited, such
                                    liability is limited to the remedies
                                    required of us under applicable law
                                    (including the Australian Consumer Law).
                                    This liability clause is subject to your
                                    rights under the Australian Consumer Law and
                                    nothing in these Booking Terms and
                                    Conditions is intended to limit any rights
                                    you may have under the Competition and
                                    Consumer Act.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Supplier/ Consolidator Insolvency</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Supplier/ Consolidator Insolvency
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  Without limiting section 22, for the avoidance
                                  of doubt, in the event of a third-party
                                  service provider being unable to provide you
                                  with the product or service you have booked
                                  due to that third-party provider becoming
                                  insolvent or being placed under external
                                  administration, subject to your refund and
                                  remedy rights under the Australian Consumer
                                  Law, we have no obligation to reimburse you
                                  for the cost of your booking, or for any loss
                                  or damage you may otherwise suffer as a result
                                  of any such insolvency or external
                                  administration.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Baggage Information</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Baggage Information
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  Please confirm the baggage allowance on your
                                  booked fare before ticket confirmation.
                                  Baggage allowance varies from airline to
                                  airline and sector.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Special Requirements</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Special Requirements
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    Please liaise with us regarding any special
                                    requirements you may have for your travel
                                    arrangements, such as Special meals, seat
                                    preference, special assistance, wheelchair
                                    request and etc. must be advised to us at
                                    least 96 hours before departure. All
                                    requests are subject to the airline's
                                    approval.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Advance seat reservations are a totally
                                    chargeable service now and allocated FREE
                                    seat data can be changed by the airline upon
                                    payment. Chargeable seats can be added until
                                    48hrs before the flight departure. For
                                    Family Travelling together, arrange your
                                    seating before your flight departure using
                                    Manage Booking or via Online Check-in using
                                    the airline website.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    STPC / Free hotel accommodation (If
                                    applicable in Fare rules) in transit country
                                    is subject to confirmation, My Flight Zone
                                    will not be responsible for any
                                    Non-Confirmation of Hotel Accommodation in
                                    case of long transit hours. Therefore, we
                                    advise you to recheck your flight timings,
                                    layover time in the transit country, and
                                    visa conditions of the transit country.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Please note that infants turning 2 years
                                    during travel will be charged child fare
                                    plus a re-issue fee on onward, return, or
                                    both journeys.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    You are responsible for ensuring that the
                                    infant is turning 2 years old during travel
                                    and advice. Children traveling with their
                                    relatives/guardians may require authority
                                    from both of their parents.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Please check the requirements with the
                                    respective airline and departure/destination
                                    country. A minor child must be accompanied
                                    by their natural parents all the time.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    No airline provides accommodation during
                                    transit
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    <strong>Airport Check-In,</strong>
                                  </p>
                                  <p>
                                    For International Travel: We advise you to
                                    reach the airport 03 hours before departure.
                                    Airlines close the check-in counter 01 hour
                                    before the flight departure time.
                                  </p>
                                  <p>
                                    For Domestic Travel: We advise reaching the
                                    airport 90 minutes before the flight.
                                    Airlines close the check-in counter 01 hour
                                    before the flight departure time.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Price</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Price
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  All prices are subject to availability and can
                                  be withdrawn or varied without notice. The
                                  price is only guaranteed once your booking has
                                  been paid for in full by you. Please note that
                                  prices quoted are subject to change. Price
                                  changes may occur because of matters outside
                                  our control that increase the product or
                                  service cost. Such factors include, but are
                                  not limited to, adverse currency fluctuations,
                                  fuel surcharges, taxes, and airfare increases.
                                  Please get in touch with your consultant for
                                  up-to-date prices.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>
                                Agent Assisted booking Through Call Centre
                              </span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Agent Assisted booking Through Call Centre
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    Please be advised that a booking fee of
                                    AUD25.00 per person applies for any booking
                                    made over the phone through our contact
                                    center, plus the Service fees as per the
                                    conditions of International or domestic
                                    travel applicable in the Terms and
                                    Conditions listed above.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    This fee reflects the reasonable, direct,
                                    and indirect costs, time, and effort
                                    incurred by our travel Consultants on
                                    booking new flights for travellers.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Online Bookings</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Online Bookings
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    Bookings made online are only for flights
                                    departing from Australia. We hold the right
                                    to reject online bookings if we believe they
                                    are not genuine or fraudulent. Some online
                                    fares will include booking fees or service
                                    charges. We hold the right to ask you for
                                    further information regarding your credit
                                    card when purchasing online. This may
                                    include a photocopy of your passport or
                                    driver's license and the credit card that
                                    you are paying with. We hold the right to
                                    refuse your booking, including your payment.
                                    The credit card name must match the
                                    traveling passenger's name, and the airline
                                    may ask for the credit card used for booking
                                    at check-in time.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    All Flights will have a booking fee of AUD
                                    15.00 on all One-way Flights and AUD 25.00
                                    on all Return flights.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5" id="refund">
                      <Disclosure defaultOpen={openSection === "refund"}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Refund</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Refund
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    The turnaround time from your request for a
                                    refund to the date you are provided with
                                    your refund may take 08 - 12 weeks, or in
                                    some situations, suppliers can take longer
                                    than 06 months to process a refund.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Your refund will be processed in the
                                    original payment method/currency as soon as
                                    we receive the money back from the supplier.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Any Refund to an International Bank overseas
                                    will have a one-time transaction fee of AUD
                                    75.00. The conversion rate is not
                                    guaranteed, and it is subject to the Bank
                                    rates.
                                  </p>
                                  <p>&nbsp;</p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Frequent Flyer</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Frequent Flyer
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  Please let us know your frequent flyer
                                  membership details (or other applicable
                                  loyalty program details) for inclusion in your
                                  booking. Please check your frequent flyer
                                  program (or other applicable loyalty programs)
                                  for the specific terms of your membership. We
                                  cannot guarantee that the supplier of your
                                  frequent flyer or other loyalty programs will
                                  credit you with points for your booking or
                                  provide any particular additional benefit,
                                  including (without limitation) status credits
                                  or lounge access. You are responsible for
                                  checking whether your booking is eligible for
                                  such benefits.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Schedule Changes</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Schedule Changes
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  We recommend contacting the travel service
                                  provider to confirm your scheduled departure
                                  time 24 hours before departure. For all
                                  schedule Changes due to the airlines or any
                                  unforeseen circumstances, My Flight Zone will
                                  charge a fee of AUD 50.00 per booking.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>
                                Hotel/Holiday Package Booking Conditions
                              </span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Hotel/Holiday Package Booking Conditions
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    Booking will be confirmed only on the
                                    availability of the hotel mentioned and
                                    after receiving the advance payment. Rates
                                    are calculated based on hotel and room
                                    category and are valid for instant purchase
                                    at the dollar rate. In case of room type is
                                    not mentioned calculation is based on the
                                    standard room category of the hotel.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    In case of the non-availability of a room in
                                    the hotel selected, a similar hotel category
                                    will be given to you as an alternate. My
                                    Flight Zone does not have any hidden charge
                                    except excluding items like your lunch,
                                    personal expenses, etc.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Hotel for each category is selected per the
                                    budget and through our location of hotels
                                    mentioned to make sure it is as per your
                                    preferences before confirming the package.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Price can be changed at any point in time
                                    depending on the Hotels My Flight Zone is
                                    not responsible for any changes.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>
                                Cancellations and Refund - Hotel and Holiday
                                Package
                              </span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Cancellations and Refund - Hotel and Holiday
                                  Package
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    My Flight Zone reserves the right to cancel
                                    bookings without notice if the deposit or
                                    final payment is not paid by the due date.
                                    Once paid, the deposit is non-refundable and
                                    is in addition to any fees imposed by the
                                    supplier, which may be up to 100% of the
                                    monies paid. There will be no refund for
                                    cancellation once travel has commenced or
                                    for any unused services. Requests for
                                    refunds received after the departure date
                                    will not be accepted. The Suppliers'
                                    cancellation fees will be charged according
                                    to their specific cancellation policy.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    My Flight Zone will charge a
                                    cancellation/amendment fee of AUD 150.00 in
                                    addition to the cancellation or amendment
                                    fee of the Hotel / Airline / Supplier.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Refund requests must provide in a written
                                    document via email.
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Check-In Requirements - Hotel</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Check-In Requirements - Hotel
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    Upon check-in, many hotels require a
                                    security bond in the form of a credit card
                                    imprint. Additionally, some hotels do not
                                    have 24-hour Reception and check-in. To
                                    avoid any inconvenience, we recommend
                                    sharing all your arrival and departure
                                    information with us so that we can advise
                                    all services at the time of booking.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Early check-in and late check-out are
                                    subject to room availability. Possibility
                                    and confirmation of the special requests and
                                    services depend on the hotels.
                                  </p>
                                  <p>&nbsp;</p>
                                  <p>
                                    Policy and availability. (Standard Check-in
                                    time: - 02:00 pm, Standard Check-Out time: -
                                    12:00 noon)
                                  </p>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>
                                Special Requests and Bedding Request - Hotel
                              </span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Special Requests and Bedding Request - Hotel
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  Please ensure any Special/Bedding requests
                                  have been noted for any applicable services.
                                  Our suppliers will endeavour to meet these
                                  requests, but they cannot be guaranteed.
                                  Charges for additional requirements including,
                                  but not limited to, infant cots, rollaway
                                  beds, in hotels, and insurance/excess
                                  reductions for rental vehicles may be applied
                                  by the Service Provider and are payable
                                  direct. Air-conditioning is not standard in
                                  holiday accommodation, requests for
                                  air-conditioning will be referred to the
                                  accommodation provider but cannot be
                                  guaranteed.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Local tours/Sightseeing Services</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Local tours/Sightseeing Services
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  Sightseeing tour itineraries, operating times
                                  and/or overnight hotels are subject to change
                                  without notice and are based on local times.
                                  Additionally, any sightseeing/transfers,
                                  including watercraft, are subject to weather
                                  and sea conditions. Therefore, please
                                  reconfirm all tour services 24 hours before
                                  travel directly with the Service Provider on
                                  the telephone numbers provided.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Transfers</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Transfers
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  Please ensure the flight details are correct
                                  if your itinerary includes airport transfers.
                                  If any changes are made to your flight
                                  information, please get in touch with your
                                  Tour operator or My Flight Zone so we can
                                  ensure that your land arrangements are
                                  adjusted accordingly.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Privacy Laws</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Privacy Laws
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  We are committed to protecting your personal
                                  information and agree to handle your
                                  information in accordance with applicable
                                  privacy, data protection, data breach
                                  notification, and anti-spam laws ("Privacy
                                  Laws"). By providing personal information, you
                                  consent to us collecting, using, and
                                  disclosing your personal information as
                                  provided in these Booking Terms and
                                  Conditions, in accordance with all Privacy
                                  Laws. In particular, you agree that we are
                                  permitted to disclose your personal
                                  information to overseas recipients in certain
                                  circumstances (such as when you request us to
                                  book international travel for you). Such
                                  recipients may include overseas travel service
                                  providers, e.g.: airlines with whom you make a
                                  booking. These travel service providers will
                                  in most cases receive your personal
                                  information in the country where they will
                                  provide the services to you or where their
                                  business is based. Generally, we will only
                                  disclose your personal information to these
                                  entities and/or persons in connection with the
                                  facilitation of your travel booking and/or to
                                  enable them to perform administrative and
                                  technical services on our behalf. Where we
                                  disclose your personal information to any
                                  entity and/or person (including any overseas
                                  recipients), you agree that: (i) in the case
                                  of travel service providers or other
                                  recipients who are providing services directly
                                  to you, we will not be required to ensure that
                                  recipient's compliance with Australian privacy
                                  laws or otherwise be accountable for how they
                                  handle your personal information and you
                                  acknowledge and agree that your rights in
                                  relation to their handling of your personal
                                  information are governed by the contract
                                  between you and that recipient (which may
                                  include their privacy policy); and (ii) in the
                                  case of travel service providers who perform
                                  services for us, we will take reasonable steps
                                  to ensure that recipient's compliance with
                                  Australian privacy laws.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5" id="lay-by">
                      <Disclosure defaultOpen={openSection === "lay-by"}>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Lay-by Service</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Lay-by Service
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  <p>
                                    My Flight Zone Lay-by provides a lay-by
                                    payment arrangement for the customer's
                                    procurement of Travel Related Products (the
                                    Service). This service is governed by the
                                    terms of the My Flight Zone Lay-by Service
                                    Agreement.
                                  </p>
                                  <br />
                                  <p>
                                    The term 'layaway' is referred to as
                                    'lay-by' in some jurisdictions. For the
                                    purpose of this agreement, the terms
                                    'layaway' and 'lay-by' are used
                                    interchangeably.
                                  </p>
                                  <br />
                                  <p>
                                    Payment of the non-refundable deposit
                                    signifies your consent to be bound by the
                                    terms of the My Flight Zone Lay-by
                                    Agreement.
                                  </p>

                                  <br />
                                  <p>The My Flight Zone Lay-by Agreement</p>
                                  <br />

                                  <ol
                                    style={{
                                      listStyle: "none",
                                      paddingLeft: 0,
                                    }}
                                  >
                                    {/* 1.1 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.1.
                                      </span>
                                      This agreement pertains to the My Flight
                                      Zone Lay-by service, referring to the
                                      lay-by payment arrangement for the
                                      customer's procurement of Travel Related
                                      Products.
                                    </li>

                                    {/* 1.2 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.2.
                                      </span>
                                      By using our Service to purchase a Travel
                                      Related Product, you provide us with
                                      unconditional and irrevocable consent and
                                      authorization to pay the Travel Provider
                                      on your behalf in exchange for your
                                      agreement to pay us in accordance with
                                      this Agreement. This includes the agreed
                                      amounts (which may include any applicable
                                      taxes, duties, or other charges by the
                                      Travel Provider) and payment on the dates
                                      outlined in your Payment Schedule, plus
                                      any additional applicable fees.
                                    </li>

                                    {/* 1.3 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.3.
                                      </span>
                                      All prices quoted are in Australian
                                      dollars unless otherwise specifically
                                      noted.
                                    </li>

                                    {/* 1.4 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.4.
                                      </span>
                                      Our Service allows you to purchase your
                                      preferred Travel Related Product offered
                                      by your chosen Travel Provider.
                                    </li>

                                    {/* 1.5 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.5.
                                      </span>
                                      We will provide you with a Payment
                                      Schedule at the time of quotation, which
                                      will allow you to customize:
                                      <ol
                                        style={{
                                          listStyleType: "lower-alpha",
                                          marginTop: "0.5em",
                                          paddingLeft: "1.5em",
                                        }}
                                      >
                                        <li style={{ marginBottom: "0.5em" }}>
                                          Whether to pay your installments
                                          weekly or fortnightly;
                                        </li>
                                        <li style={{ marginBottom: "0.5em" }}>
                                          When you would like your installments
                                          to start; and/or
                                        </li>
                                        <li style={{ marginBottom: "0.5em" }}>
                                          How long you would like your lay-by
                                          period to be (up to 2 months), with
                                          the final balance due at least 2 weeks
                                          prior to departure.
                                        </li>
                                      </ol>
                                    </li>

                                    {/* 1.6 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.6.
                                      </span>
                                      My Flight Zone Lay-by will, to the best of
                                      our ability, contact you if payments are
                                      missed, including via SMS, email, or
                                      phone.
                                    </li>

                                    {/* 1.7 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.7.
                                      </span>
                                      After three attempts to contact you, we
                                      will cancel the ticket and deduct the
                                      relevant fees from your given card or from
                                      the amount you have already paid.
                                    </li>

                                    {/* 1.8 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.8.
                                      </span>
                                      We will not charge you a late fee;
                                      however, your financial institution may
                                      charge a dishonor fee in the event of
                                      failed payments.
                                    </li>

                                    {/* 1.9 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.9.
                                      </span>
                                      My Flight Zone Lay-by does not issue
                                      credit nor perform any credit checks. We
                                      offer a lay-by (or layaway) service only.
                                    </li>

                                    {/* 1.10 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.10.
                                      </span>
                                      We hold your Travel Related Product
                                      unencumbered and in trust for you while
                                      you pay off the balance owed to My Flight
                                      Zone Lay-by.
                                    </li>

                                    {/* 1.11 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.11.
                                      </span>
                                      Once you receive your e-ticket, you will
                                      have access to your flight information via
                                      your preferred Travel Provider platform.
                                    </li>

                                    {/* 1.12 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.12.
                                      </span>
                                      Your submission of personal information
                                      through our site or via other channels
                                      such as SMS, email, or phone will be
                                      governed by the terms of our Privacy
                                      Policy.
                                    </li>

                                    {/* 1.13 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.13.
                                      </span>
                                      We will only use your personal information
                                      to facilitate payments with our
                                      third-party provider and your Travel
                                      Related Product bookings with your chosen
                                      Travel Provider.
                                    </li>

                                    {/* 1.14 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.14.
                                      </span>
                                      All payment information will be encrypted
                                      by our third-party payment provider,
                                      Stripe.
                                    </li>

                                    {/* 1.15 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.15.
                                      </span>
                                      A non-refundable deposit of at least 10%
                                      of your total quote must be paid.
                                    </li>

                                    {/* 1.16 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.16.
                                      </span>
                                      You must provide a valid debit or credit
                                      card for payment (the Nominated Payment
                                      Option).
                                    </li>

                                    {/* 1.17 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.17.
                                      </span>
                                      By entering into a payment arrangement
                                      with My Flight Zone Lay-by, you authorize
                                      us to debit your Nominated Payment Option
                                      for the amounts and on the dates specified
                                      in the Payment Schedule.
                                    </li>

                                    {/* 1.18 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.18.
                                      </span>
                                      If your Nominated Payment Option changes,
                                      you agree to promptly update your payment
                                      details to ensure uninterrupted service.
                                      Please contact our Customer Service Team
                                      at{" "}
                                      <a href="mailto:lay-buy@myflightzone.com.au">
                                        lay-buy@myflightzone.com.au
                                      </a>{" "}
                                      for updates.
                                    </li>

                                    {/* 1.19 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.19.
                                      </span>
                                      If you wish to alter your Payment
                                      Schedule, please notify us in writing at
                                      least 48 hours prior to the scheduled
                                      payment date. Any notifications received
                                      within 48 hours of, or after the scheduled
                                      payment, may not be processed in time.
                                      Contact{" "}
                                      <a href="mailto:lay-buy@myflightzone.com.au">
                                        lay-buy@myflightzone.com.au
                                      </a>{" "}
                                      for such requests.
                                    </li>

                                    {/* 1.20 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.20.
                                      </span>
                                      You are responsible for ensuring
                                      sufficient funds are available in your
                                      Nominated Payment Option. If a scheduled
                                      payment fails, you must contact us to
                                      rectify the issue. Failure to resolve the
                                      payment before the next payment date may
                                      result in the loss of your reservation or
                                      price increases.
                                    </li>

                                    {/* 1.21 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.21.
                                      </span>
                                      Full payment must be made at least 1 week
                                      prior to the departure date.
                                    </li>

                                    {/* 1.22 */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        1.22.
                                      </span>
                                      You agree to provide accurate and
                                      up-to-date account information, including
                                      your email, phone number, and
                                      identification details, to ensure smooth
                                      transaction processing and communication.
                                    </li>

                                    {/* 2. Indemnification */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        2.
                                      </span>
                                      <strong>Indemnification</strong>
                                      <ol
                                        style={{
                                          listStyleType: "decimal",
                                          marginTop: "0.5em",
                                          paddingLeft: "1.5em",
                                        }}
                                      >
                                        <li style={{ marginBottom: "0.5em" }}>
                                          You agree to indemnify, defend, and
                                          hold harmless My Flight Zone Lay-by,
                                          its parent company, subsidiaries,
                                          affiliates, and all related personnel
                                          from any claim or demand arising from
                                          your breach of this Agreement, or your
                                          violation of any law or third-party
                                          rights.
                                        </li>
                                      </ol>
                                    </li>

                                    {/* 3. Termination */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        3.
                                      </span>
                                      <strong>Termination</strong>
                                      <ol
                                        style={{
                                          listStyleType: "decimal",
                                          marginTop: "0.5em",
                                          paddingLeft: "1.5em",
                                        }}
                                      >
                                        <li style={{ marginBottom: "0.5em" }}>
                                          This Agreement remains effective
                                          unless terminated by either you or us.
                                          You may terminate these Terms of
                                          Service by notifying My Flight Zone
                                          Lay-by in writing that you no longer
                                          wish to use our Services.
                                        </li>
                                        <li style={{ marginBottom: "0.5em" }}>
                                          Any obligations or liabilities
                                          incurred before termination will
                                          survive the termination of this
                                          Agreement.
                                        </li>
                                        <li style={{ marginBottom: "0.5em" }}>
                                          If we determine, in our sole judgment,
                                          that you have breached this Agreement,
                                          My Flight Zone Lay-by may terminate
                                          the Agreement without notice. You will
                                          remain liable for all amounts due up
                                          to the termination date.
                                        </li>
                                      </ol>
                                    </li>

                                    {/* 4. Interpretation */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        4.
                                      </span>
                                      <strong>Interpretation</strong>
                                      <ol
                                        style={{
                                          listStyleType: "decimal",
                                          marginTop: "0.5em",
                                          paddingLeft: "1.5em",
                                        }}
                                      >
                                        <li style={{ marginBottom: "0.5em" }}>
                                          Headings are for convenience only and
                                          do not affect interpretation.
                                        </li>
                                        <li style={{ marginBottom: "0.5em" }}>
                                          Any failure by My Flight Zone Lay-by
                                          to enforce any provision of this
                                          Agreement does not constitute a waiver
                                          of that provision.
                                        </li>
                                        <li style={{ marginBottom: "0.5em" }}>
                                          This Agreement, together with any
                                          policies posted by My Flight Zone
                                          Lay-by, constitutes the entire
                                          understanding between you and My
                                          Flight Zone Lay-by, superseding all
                                          prior agreements.
                                        </li>
                                        <li style={{ marginBottom: "0.5em" }}>
                                          Any ambiguities in this Agreement will
                                          not be construed against the drafting
                                          party.
                                        </li>
                                        <li style={{ marginBottom: "0.5em" }}>
                                          If any provision of these Terms of
                                          Service is deemed unlawful or
                                          unenforceable, the remainder of the
                                          Terms shall remain valid and
                                          enforceable.
                                        </li>
                                      </ol>
                                    </li>

                                    {/* 5. Contact Information */}
                                    <li
                                      style={{
                                        marginBottom: "1em",
                                        position: "relative",
                                        paddingLeft: "3em",
                                      }}
                                    >
                                      <span
                                        style={{
                                          position: "absolute",
                                          left: 0,
                                          fontWeight: "bold",
                                        }}
                                      >
                                        5.
                                      </span>
                                      <strong>Contact Information</strong>
                                      <p style={{ marginTop: "0.5em" }}>
                                        Questions about this Agreement or any
                                        Terms of Service should be directed to{" "}
                                        <a href="mailto:lay-buy@myflightzone.com.au">
                                          lay-buy@myflightzone.com.au
                                        </a>
                                        .
                                      </p>
                                    </li>
                                  </ol>
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>

                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Modifications of Terms and Conditions</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Modifications of Terms and Conditions
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  We reserve the right to modify any of these
                                  Booking Terms and Conditions at any time
                                  without prior notice. If we change any of
                                  these terms and conditions, we will post the
                                  amended terms and conditions on the website,
                                  applications, or services, which are effective
                                  upon posting. The applicable terms that apply
                                  are those in effect at the time you make a
                                  booking. These terms were last updated on 01
                                  January 2023.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                    <div className="mt-5">
                      <Disclosure>
                        {({ open }) => (
                          <>
                            <Disclosure.Button className="flex justify-between w-full px-4 py-2 text-sm font-medium text-left text-blue-900 bg-blue-100 rounded-lg hover:bg-blue-200 focus:outline-none focus-visible:ring focus-visible:ring-blue-500 focus-visible:ring-opacity-75">
                              <span>Governing State Law</span>
                              <ChevronDownIcon
                                className={`${
                                  open ? "transform rotate-90" : ""
                                } w-5 h-5 text-blue-500`}
                              />
                            </Disclosure.Button>
                            <Disclosure.Panel className=" pt-4 pb-2 text-sm">
                              <div className="p-6 bg-neutral-50 dark:bg-neutral-800 rounded-2xl dark:border-neutral-800">
                                <h3 className="text-2xl font-semibold leading-none text-neutral-900 md:text-3xl dark:text-neutral-200">
                                  Governing State Law
                                </h3>
                                <span className="block text-sm text-neutral-500 mt-3 sm:text-base dark:text-neutral-400">
                                  If any dispute arises between you and us, the
                                  laws of Australia will apply. You irrevocably
                                  and unconditionally submit to the exclusive
                                  jurisdiction of the courts of Australia and
                                  waive any right that you may have to object to
                                  an action being brought in those courts. These
                                  terms are governed by the laws in Victoria,
                                  Australia.
                                </span>
                              </div>
                            </Disclosure.Panel>
                          </>
                        )}
                      </Disclosure>
                    </div>
                  </div>
                </span>
              </div>{" "}
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default PageTermsAndConditions;
