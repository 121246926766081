import React, { useState, useEffect } from "react";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import axios, { AxiosError } from "axios";
import ReCAPTCHA from "react-google-recaptcha";
import moment from "moment";
import {
  OrderDetails,
  PaymentIntentResponse,
  SubscriptionResponse,
} from "./models";

const API_URL = process.env.REACT_APP_API_URL;
const reCaptchaSiteKey = process.env.REACT_APP_RECAPTCHA_SITE_KEY || "";

const cardElementOptions = {
  style: {
    base: {
      fontSize: "16px",
      color: "#fff",
      fontFamily: '"Helvetica Neue", Helvetica, sans-serif',
      "::placeholder": {
        color: "#aab7c4",
      },
    },
    invalid: {
      color: "#fa755a",
    },
  },
  hidePostalCode: true,
};

interface PaymentFormProps {
  orderDetails: OrderDetails;
}

const PaymentForm: React.FC<PaymentFormProps> = ({ orderDetails }) => {
  const stripe = useStripe();
  const elements = useElements();
  const [customerEmail, setCustomerEmail] = useState<string>("");
  const [paymentInterval, setPaymentInterval] = useState<
    "weekly" | "fortnightly"
  >("weekly");
  const [status, setStatus] = useState<
    "idle" | "processing" | "success" | "error"
  >("idle");
  const [statusMessage, setStatusMessage] = useState<string>("");
  const [recaptchaToken, setRecaptchaToken] = useState<string | null>(null);
  const [isPaymentComplete, setIsPaymentComplete] = useState<boolean>(false);
  const [firstPaymentDate, setFirstPaymentDate] = useState<string>("");
  const [termsAccepted, setTermsAccepted] = useState<boolean>(false);

  const {
    depositDueToday,
    weeklyPayment,
    fortnightlyPayment,
    remainingBalance,
    weeklyFirstPaymentDate,
    fortnightlyFirstPaymentDate,
  } = orderDetails.paymentDetails;

  useEffect(() => {
    updateFirstPaymentDate(paymentInterval);
  }, [paymentInterval, weeklyFirstPaymentDate, fortnightlyFirstPaymentDate]);

  const updateFirstPaymentDate = (interval: "weekly" | "fortnightly") => {
    const date =
      interval === "weekly"
        ? weeklyFirstPaymentDate
        : fortnightlyFirstPaymentDate;
    setFirstPaymentDate(moment(date).format("MMMM DD, YYYY"));
  };

  const handleIntervalChange = (interval: "weekly" | "fortnightly") => {
    setPaymentInterval(interval);
    updateFirstPaymentDate(interval);
  };

  const handlePaymentIntent = async (e: React.FormEvent) => {
    e.preventDefault();
    if (!stripe || !elements) {
      setStatus("error");
      setStatusMessage("Payment has not been initialized.");
      return;
    }

    if (!recaptchaToken) {
      setStatus("error");
      setStatusMessage("Please complete the reCAPTCHA.");
      return;
    }

    if (!termsAccepted) {
      setStatus("error");
      setStatusMessage("Please accept the terms and conditions.");
      return;
    }

    setStatus("processing");
    setStatusMessage("Processing your deposit payment...");

    try {
      const response = await axios.post<PaymentIntentResponse>(
        `${API_URL}/payment_plan/payment-intent/create`,
        {
          orderId: orderDetails.id,
          customerEmail,
          depositAmount: depositDueToday,
          recaptchaToken,
        }
      );

      const { clientSecret } = response.data;
      setStatusMessage("Confirming payment...");

      const cardElement = elements.getElement(CardElement);
      if (!cardElement) {
        throw new Error("Card Element not found");
      }

      const paymentResult = await stripe.confirmCardPayment(clientSecret, {
        payment_method: {
          card: cardElement,
          billing_details: {
            email: customerEmail,
          },
        },
      });

      if (paymentResult.error) {
        throw new Error(paymentResult.error.message);
      }

      if (paymentResult.paymentIntent?.status === "succeeded") {
        setStatusMessage("Setting up payment plan...");
        await handleSubscription(
          paymentResult.paymentIntent.payment_method as string
        );
      }
    } catch (error) {
      setStatus("error");
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ error: string }>;
        setStatusMessage(
          axiosError.response?.data?.error || "An unknown error occurred"
        );
      } else if (error instanceof Error) {
        setStatusMessage(error.message);
      } else {
        setStatusMessage("An unknown error occurred");
      }
    }
  };

  const handleSubscription = async (paymentMethodId: string) => {
    try {
      await axios.post<SubscriptionResponse>(
        `${API_URL}/payment_plan/subscription/create`,
        {
          orderId: orderDetails.id,
          customerEmail,
          paymentMethodId,
          paymentInterval,
        }
      );

      setStatus("success");
      setStatusMessage(
        "Payment successful! Your subscription has been set up."
      );
      setIsPaymentComplete(true);
    } catch (error) {
      setStatus("error");
      if (axios.isAxiosError(error)) {
        const axiosError = error as AxiosError<{ error: string }>;
        setStatusMessage(
          axiosError.response?.data?.error ||
            "Failed to set up subscription. Please try again."
        );
      } else if (error instanceof Error) {
        setStatusMessage(error.message);
      } else {
        setStatusMessage("Failed to set up subscription. Please try again.");
      }
    }
  };

  if (isPaymentComplete) {
    return (
      <div className="text-white">
        <h2 className="text-2xl font-bold mb-4">Payment Successful!</h2>
        <p>
          Your deposit has been paid and your subscription has been set up
          successfully.
        </p>
      </div>
    );
  }

  return (
    <form onSubmit={handlePaymentIntent} className="space-y-6 text-white">
      <div className="bg-gray-800 p-4 rounded-lg">
        <p className="text-sm">
          Lock in your deal with a small deposit through our Flight Zone Payment
          Plan. Pay the rest in easy installments.
        </p>
      </div>

      <div>
        <h2 className="text-xl mb-2">Deposit amount</h2>
        <p className="text-3xl font-bold text-indigo-400">
          ${depositDueToday}{" "}
        </p>
      </div>

      <div>
        <h2 className="text-xl mb-2">Choose your payment plan</h2>
        <div className="grid grid-cols-2 gap-4">
          {["weekly", "fortnightly"].map((plan) => (
            <button
              key={plan}
              type="button"
              className={`p-3 rounded-lg text-left ${
                paymentInterval === plan
                  ? "bg-indigo-600 text-white"
                  : "bg-gray-700 text-gray-300"
              }`}
              onClick={() =>
                handleIntervalChange(plan as "weekly" | "fortnightly")
              }
            >
              <div className="font-medium capitalize">{plan}</div>
              <div className="text-sm">
                ${plan === "weekly" ? weeklyPayment : fortnightlyPayment} x{" "}
                {plan === "weekly" ? "8" : "4"}
              </div>
            </button>
          ))}
        </div>
      </div>

      <div className="flex justify-between text-sm">
        <div>
          <p>First Payment</p>
          <p>+{paymentInterval === "weekly" ? "7" : "3"} more payments</p>
          <p>Final Payment</p>
        </div>
        <div className="text-right">
          <p>{firstPaymentDate}</p>
          <p>&nbsp;</p>
          <p>&nbsp;</p>
        </div>
      </div>

      <div>
        <h2 className="text-xl mb-2">Purchase Summary</h2>
        <div className="space-y-2 text-sm">
          <div className="flex justify-between">
            <span>Tour Package Price</span>
            <span>${orderDetails.totalAmount.toFixed(2)}</span>
          </div>
          <div className="flex justify-between">
            <span>Remaining Balance</span>
            <span>${remainingBalance}</span>
          </div>
          <div className="flex justify-between font-bold">
            <span>Deposit (due today)</span>
            <span>${depositDueToday}</span>
          </div>
        </div>
      </div>

      <input
        type="email"
        className="w-full p-3 bg-gray-800 rounded text-white"
        placeholder="Your email"
        value={customerEmail}
        onChange={(e) => setCustomerEmail(e.target.value)}
        required
      />

      <div className="bg-gray-800 rounded p-3">
        <CardElement options={cardElementOptions} />
      </div>

      <div className="flex items-center">
        <input
          type="checkbox"
          id="terms-checkbox"
          checked={termsAccepted}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            setTermsAccepted(e.target.checked)
          }
          className="mr-2"
        />
        <label htmlFor="terms-checkbox" className="text-sm">
          I have read and accepted the{" "}
          <a
            href="/terms-and-conditions?section=lay-by"
            target="_blank"
            rel="noopener noreferrer"
            className="text-blue-400 underline"
          >
            Terms and Conditions
          </a>
        </label>
      </div>

      {status === "error" && !termsAccepted && (
        <div className="text-red-500 text-sm">
          Please accept the terms and conditions.
        </div>
      )}

      <div className="mt-4">
        <ReCAPTCHA
          sitekey={reCaptchaSiteKey}
          theme="dark"
          onChange={(token) => setRecaptchaToken(token)}
          onExpired={() => setRecaptchaToken(null)}
        />
      </div>

      {status === "processing" && (
        <div className="bg-blue-500 text-white p-2 rounded">
          {statusMessage}
        </div>
      )}
      {status === "success" && (
        <div className="bg-green-500 text-white p-2 rounded">
          {statusMessage}
        </div>
      )}
      {status === "error" && (
        <div className="bg-red-500 text-white p-2 rounded">{statusMessage}</div>
      )}

      <button
        type="submit"
        className="w-full p-3 bg-indigo-600 rounded text-white font-bold"
        disabled={status === "processing" || !recaptchaToken || !termsAccepted}
      >
        {status === "processing" ? "Processing..." : "Pay Deposit"}
      </button>
    </form>
  );
};

export default PaymentForm;
